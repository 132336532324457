import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { reactStrictMode } from './config';
import reportWebVitals from './reportWebVitals';

// @see https://webpack.js.org/guides/public-path/#on-the-fly

const getScriptPublicPath = () => {
  if (window.salesFrontendBaseUrl) {
    if (window.salesFrontendBaseUrl.endsWith('/')) {
      return window.salesFrontendBaseUrl;
    } else {
      return `${window.salesFrontendBaseUrl}/`;
    }
  }
  if (document.currentScript?.src?.startsWith('http://localhost')) {
    return undefined;
  }
  if (document.currentScript?.src?.startsWith('https://')) {
    return `https://${document.currentScript.src.split('/')[2]}/${
      document.currentScript.src.split('/')[3]
    }/`;
  }
  if (document.currentScript?.src?.startsWith('http://')) {
    return `http://${document.currentScript.src.split('/')[2]}/${
      document.currentScript.src.split('/')[3]
    }/`;
  }
  return undefined;
};

const publicScriptPath = getScriptPublicPath();
if (publicScriptPath) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = publicScriptPath;
}

// map the found html attributes on the container as prop into the app
const initializeReactMountNode = mountNode => {
  const attributes = mountNode.getAttributeNames();
  const appProps = {};
  attributes.forEach(name => {
    if (name.startsWith('json-')) {
      appProps[name.substr(5)] = JSON.parse(mountNode.getAttribute(name));
    } else if (name !== 'data') {
      appProps[name] = mountNode.getAttribute(name);
    } else {
      console.warn(
        'Cannot supply a templated property named "data". These are reserved.',
      );
    }
  });

  console.debug('Found react mountNode', mountNode, appProps);
  const root = ReactDOM.createRoot(mountNode);
  if (reactStrictMode) {
    root.render(
      <React.StrictMode>
        <App {...appProps} />
      </React.StrictMode>,
    );
  } else {
    root.render(<App {...appProps} />);
  }
};

// initialize all found react containers
const reactRootContainers = document.getElementsByClassName(
  'sales-frontend-root',
);
if (reactRootContainers) {
  for (let i = 0; i < reactRootContainers.length; i++) {
    initializeReactMountNode(reactRootContainers[i]);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
