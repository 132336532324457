import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from './translations';

i18n.use(LanguageDetector).init({
  fallbackLng: 'de',
  debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false, // not needed for react!!
    prefix: '%{',
    suffix: '}',
  },

  resources: translations,
});

export const changeLanguage = lng => {
  i18n.changeLanguage(lng);
};

export default i18n;
