// This file contains consts specific to this project,
// and will not be copied/overwritten when autosyncing the config

export const BACKEND_ROUTE = {
  PROFILE: 'PROFILE',
  LEAD_LIST: 'LEAD_LIST',
  LEAD_CREATE: 'LEAD_CREATE',
  LEAD: 'LEAD',
  LEAD_ID_LIST: 'LEAD_ID_LIST',
  CALENDAR_ENTRY_LIST: 'CALENDAR_ENTRY_LIST',
  CALENDAR_ENTRY_CREATE: 'CALENDAR_ENTRY_CREATE',
  CALENDAR_ENTRY: 'CALENDAR_ENTRY',
  DEVICE_TOKEN_CREATE: 'DEVICE_TOKEN_CREATE',
  DEVICE_TOKEN: 'DEVICE',
  NOTE_CREATE: 'NOTE_CREATE',
  NOTE: 'NOTE',
  TEAM_LIST: 'TEAM_LIST',
  USER_LIST: 'USER_LIST',
  NOTE_LIST: 'NOTE_LIST',
  MAP_LIST: 'MAP_LIST',
  MAP_CREATE: 'MAP_CREATE',
  MAP: 'MAP',
  IMAGE_LIST: 'IMAGE_LIST',
  IMAGE_ID_LIST: 'IMAGE_ID_LIST',
  IMAGE_CREATE: 'IMAGE_CREATE',
  IMAGE: 'IMAGE',
  MESSAGE: 'MESSAGE',
  MESSAGE_LIST: 'MESSAGE_LIST',
  MESSAGE_CREATE: 'MESSAGE_CREATE',
  MISC_FILE_LIST: 'MISC_FILE_LIST',
  MISC_FILE_ID_LIST: 'MISC_FILE_ID_LIST',
  MISC_FILE_CREATE: 'MISC_FILE_CREATE',
  MISC_FILE: 'MISC_FILE',
  FILE_LIST: 'FILE_LIST',
  EMAIL_ADDRESS_LIST: 'EMAIL_ADDRESS_LIST',
  EMAIL_ADDRESS_CREATE: 'EMAIL_ADDRESS_CREATE',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  PHONE_NUMBER_LIST: 'PHONE_NUMBER_LIST',
  PHONE_NUMBER_CREATE: 'PHONE_NUMBER_CREATE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  DEVICE_LIST: 'DEVICE_LIST',
  // lookup
  ADDRESS_DETAILS: 'ADDRESS_DETAILS',
  ADDRESS_SEARCH: 'ADDRESS_SEARCH',
  AREATYPE_LIST: 'AREATYPE_LIST',
  LEADPHASE_LIST: 'LEADPHASE_LIST',
  LEADSOURCE_LIST: 'LEADSOURCE_LIST',
  LOADPROFILE_LIST: 'LOADPROFILE_LIST',
  ROOFTYPE_LIST: 'ROOFTYPE_LIST',
  ROOFTILETYPE_LIST: 'ROOFTILETYPE_LIST',
  SALUTATION_LIST: 'SALUTATION_LIST',
  ZIP_AREA_ROOF_COVERING_LIST: 'ZIP_AREA_ROOF_COVERING_LIST',
  LEAD_LOST_REASON_LIST: 'LEAD_LOST_REASON_LIST',
  // binary
  FILE: 'FILE',
  // Measurement Protocol
  MEASUREMENT_PROTOCOL_LIST: 'MEASUREMENT_PROTOCOL_LIST',
  MEASUREMENT_PROTOCOL_ID_LIST: 'MEASUREMENT_PROTOCOL_ID_LIST',
  MEASUREMENT_PROTOCOL_CREATE: 'MEASUREMENT_PROTOCOL_CREATE',
  MEASUREMENT_PROTOCOL: 'MEASUREMENT_PROTOCOL',
  MEASUREMENT_PROTOCOL_ROOF_LIST: 'MEASUREMENT_PROTOCOL_ROOF_LIST',
  MEASUREMENT_PROTOCOL_ROOF_ID_LIST: 'MEASUREMENT_PROTOCOL_ROOF_ID_LIST',
  MEASUREMENT_PROTOCOL_ROOF_CREATE: 'MEASUREMENT_PROTOCOL_ROOF_CREATE',
  MEASUREMENT_PROTOCOL_ROOF: 'MEASUREMENT_PROTOCOL_ROOF',
  MEASUREMENT_PROTOCOL_ROOF_AREA_LIST: 'MEASUREMENT_PROTOCOL_ROOF_AREA_LIST',
  MEASUREMENT_PROTOCOL_ROOF_AREA_ID_LIST:
    'MEASUREMENT_PROTOCOL_ROOF_AREA_ID_LIST',
  MEASUREMENT_PROTOCOL_ROOF_AREA_CREATE:
    'MEASUREMENT_PROTOCOL_ROOF_AREA_CREATE',
  MEASUREMENT_PROTOCOL_ROOF_AREA: 'MEASUREMENT_PROTOCOL_ROOF_AREA',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_LIST:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_LIST',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_ID_LIST:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_ID_LIST',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_CREATE:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX_CREATE',
  MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX:
    'MEASUREMENT_PROTOCOL_HOUSE_CONNECTION_BOX',
} as const;

const MIME_TYPE = {
  APPLICATION_EPUB_ZIP: 'application/epub+zip',
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_MS_WORD: 'application/msword',
  APPLICATION_VND_MS_EXCEL: 'application/vnd.ms-excel',
  APPLICATION_VND_MS_POWERPOINT: 'application/vnd.ms-powerpoint',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APPLICATION_XML: 'application/xml',
  APPLICATION_ZIP: 'application/zip',
  AUDIO_M4A: 'audio/m4a',
  AUDIO_MPEG: 'audio/mpeg',
  AUDIO_OPUS: 'audio/opus',
  AUDIO_WAV: 'audio/wav',
  IMAGE_BMP: 'image/bmp',
  IMAGE_GIF: 'image/gif',
  IMAGE_HEIC: 'image/heic',
  IMAGE_HEIF: 'image/heif',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_JPG: 'image/jpg',
  IMAGE_PNG: 'image/png',
  IMAGE_SVG_XML: 'image/svg+xml',
  TEXT_CSV: 'text/csv',
  TEXT_PLAIN: 'text/plain',
  TEXT_XML: 'text/xml',
  VIDEO_MP4: 'video/mp4',
  VIDEO_MPEG: 'video/mpeg',
  VIDEO_MOV: 'video/quicktime',
} as const;
export const MIME_TYPE_PROPERTY_MAP = {
  [MIME_TYPE.APPLICATION_EPUB_ZIP]: {
    extension: '.epub',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_PDF]: {
    extension: '.pdf',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_MS_WORD]: {
    extension: '.doc',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_VND_MS_EXCEL]: {
    extension: '.xls',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_VND_MS_POWERPOINT]: {
    extension: '.ppt',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION]:
    {
      extension: '.pptx',
      isPreviewable: false,
      isValidImageType: false,
    },
  [MIME_TYPE.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET]:
    {
      extension: '.xlsx',
      isPreviewable: false,
      isValidImageType: false,
    },
  [MIME_TYPE.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT]:
    {
      extension: '.docx',
      isPreviewable: false,
      isValidImageType: false,
    },
  [MIME_TYPE.APPLICATION_XML]: {
    extension: '.xml',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.APPLICATION_ZIP]: {
    extension: '.zip',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.AUDIO_M4A]: {
    extension: '.m4a',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.AUDIO_MPEG]: {
    extension: '.mp3',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.AUDIO_OPUS]: {
    extension: '.opus',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.AUDIO_WAV]: {
    extension: '.wav',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.IMAGE_BMP]: {
    extension: '.bmp',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.IMAGE_GIF]: {
    extension: '.gif',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.IMAGE_HEIC]: {
    extension: '.heic',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.IMAGE_HEIF]: {
    extension: '.heif',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.IMAGE_JPEG]: {
    extension: '.jpeg',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.IMAGE_JPG]: {
    extension: '.jpg',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.IMAGE_PNG]: {
    extension: '.png',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.IMAGE_SVG_XML]: {
    extension: '.svg',
    isPreviewable: true,
    isValidImageType: true,
  },
  [MIME_TYPE.TEXT_CSV]: {
    extension: '.csv',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.TEXT_PLAIN]: {
    extension: '.txt',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.TEXT_XML]: {
    extension: '.xml',
    isPreviewable: false,
    isValidImageType: false,
  },
  [MIME_TYPE.VIDEO_MP4]: {
    extension: '.mp4',
    isPreviewable: true,
    isValidImageType: true,
    isValidVideoType: true,
  },
  [MIME_TYPE.VIDEO_MPEG]: {
    extension: '.mpeg',
    isPreviewable: true,
    isValidImageType: true,
    isValidVideoType: true,
  },
  [MIME_TYPE.VIDEO_MOV]: {
    extension: '.mov',
    isPreviewable: true,
    isValidImageType: true,
    isValidVideoType: true,
  },
} as const;
