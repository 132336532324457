import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMemo } from 'react';

const ekdPrimaryColor = '#F39200';
const BOX_SHADOW = {
  DEFAULT: 'rgb(0 0 0 / 14%) 0px 4px 5px 0px',
};

/**
 *
 * @param {string} mode should be 'light' or 'dark'
 * @returns a mui theme
 */
const getTheme = mode =>
  createTheme({
    palette: {
      background: {
        default: 'rgba(0,0,0,0)',
      },
      mode,
      common: {
        black: '#2C2C2C',
        white: '#ffffff',
      },
      primary: {
        main: ekdPrimaryColor,
        contrastText: '#fff',
      },
      secondary: {
        main: '#6B6B6B',
      },
      error: {
        main: '#E94A41',
        contrastText: '#fff',
      },
      warning: {
        main: '#FFEC45',
        contrastText: '#2C2C2C',
      },
      success: {
        main: '#72A520',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: 'Palanquin, sans-serif',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    components: {
      // force all typography components to receive correct font family:
      // https://github.com/mui/material-ui/issues/28633
      MuiTypography: {
        defaultProps: {
          fontFamily: 'Palanquin, sans-serif',
          color: 'rgba(0, 0, 0, 0.87)',
        },
        styleOverrides: {
          body1: {
            fontSize: '0.875rem', // 14px
            lineHeight: '1.584rem',
            fontWeight: 400,
            whiteSpace: 'break-spaces',
          },
          caption: {
            fontSize: '0.75rem', // 12px
            lineHeight: '1.358rem',
            fontWeight: 400,
          },
          h4: {
            fontSize: '1.25rem', // 20px
            lineHeight: '2.264rem',
            fontWeight: 700,
          },
          h6: {
            fontSize: '1rem', // 16px
            lineHeight: '1.811rem',
            fontWeight: 700,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 4,
            fontWeight: 600,
            boxShadow: BOX_SHADOW.DEFAULT,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: BOX_SHADOW.DEFAULT,
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            paddingLeft: 8,
            paddingRight: 8,
          },
          sizeMedium: {
            paddingLeft: 16,
            paddingRight: 16,
          },
          icon: {
            marginLeft: 0,
          },
          label: {
            paddingRight: 0,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            marginTop: 24,
            marginBottom: 24,
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 40,
            paddingLeft: 40,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
            paddingTop: 0,
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
          badge: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#000000',
          },
          textColorSecondary: {
            backgroundColor: ekdPrimaryColor,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            marginLeft: 8,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            padding: 0,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '1rem', // 16px
            lineHeight: '1.811rem',
            fontWeight: 700,
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            color: '#ffffff',
            fontSize: '1.25rem', // 20px
            lineHeight: '2.264rem',
            fontWeight: 700,
          },
        },
      },
    },
  });

const CustomThemeProvider = ({ children }) => {
  // const mode = useAppSelector(state => state.app.theme.mode)
  const theme = useMemo(() => getTheme('light'), []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
