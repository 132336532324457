/* eslint-disable */
// TODO: Fix TypeScript configuration and apply necessary changes so there is no need to disable eslint here
// TODO: Is node-fetch really still necessary here? Remove it otherwise and use regular fetch instead
import fetchToCurl from 'fetch-to-curl';
import get from 'lodash/get';
import qs from 'query-string';

import {
  APIMETHOD,
  apiMethodsWithBody,
  apiMethodsWithoutBody,
  ENCODING,
  ERROR,
  REQUESTBODYTYPE,
  withTimeout,
} from './lib';

const logPrefix = 'JS-API';

const logRequest = logger => (url, options) => {
  logger.debug(logPrefix + ' request', {
    url,
    options,
  });
  logger.debug(fetchToCurl(url, options));
};

const getErrorPromise = logger => error => {
  logger.info(logPrefix, 'Handled Connection Error', error);
  return new Promise((resolve, reject) => {
    resolve({
      ok: false,
      status: error.statusCode,
      statusText: error.errorCode,
      json: () =>
        new Promise((resolve, reject) => {
          resolve(error);
        }),
    });
  });
};

const handleFetchErrors = logger => receivedError => {
  const error = {
    statusCode: get(receivedError, 'statusCode', 503),
    devMessage: get(
      receivedError,
      'devMessage',
      'An error ocurred while trying to connect to the API.',
    ),
    errorCode: get(receivedError, 'errorCode', ERROR.NOCONNECTION),
  };
  return getErrorPromise(logger)(error);
};

const fetchWithTimeout =
  (logger, timeout = 1000) =>
  (...fetchParams) => {
    const timeOutError = {
      statusCode: 503,
      devMessage: 'The Request timed out.',
      errorCode: ERROR.TIMEOUT,
    };
    const fetchPromise = fetch(...fetchParams);
    return withTimeout(logger)(timeout, fetchPromise, timeOutError);
  };

const getQueryParamsToAppend = parameters => {
  if (!parameters) {
    return '';
  }
  const query = qs.stringify(parameters);
  if (query) {
    // TODO remove this workaround when MGMT API can handle encoded brackets
    return `?${query.replaceAll('%5B', '[').replaceAll('%5D', ']')}`;
  }
  return '';
};

export const createWithoutBody = logger => method => async request => {
  const {
    url: incUrl,
    baseUrl,
    endpoint,
    parameters,
    headers,
    timeout,
    credentials,
  } = request;
  const url =
    incUrl || `${baseUrl}/${endpoint}${getQueryParamsToAppend(parameters)}`;
  const options = {
    method,
    headers,
    credentials,
  };
  logRequest(logger)(url, options);
  return fetchWithTimeout(logger, timeout)(url, options).catch(
    handleFetchErrors(logger),
  );
};

/**
 * @deprecated use createWithoutBody(APIMETHOD.GET) instead
 */
export const createGet = logger => createWithoutBody(logger)(APIMETHOD.GET);

const encodeBody =
  (bodyType = 'json') =>
  data => {
    if (bodyType === REQUESTBODYTYPE.json) return JSON.stringify(data);
    if (bodyType === REQUESTBODYTYPE.url) return qs.stringify(data);
    if (bodyType === REQUESTBODYTYPE.binaryfrombase64) {
      const b = Buffer.from(data, ENCODING.BASE64);
      return b.buffer;
    }
    if (bodyType === REQUESTBODYTYPE.binary) return data;
    return data;
  };

export const createWithBody = logger => method => async request => {
  const {
    baseUrl,
    endpoint,
    parameters,
    data,
    headers,
    bodyType,
    timeout,
    credentials,
  } = request;
  const url =
    incUrl || `${baseUrl}/${endpoint}${getQueryParamsToAppend(parameters)}`;
  const options = {
    method,
    body: encodeBody(bodyType)(data),
    headers,
    credentials,
  };
  logRequest(logger)(url, options);
  return fetchWithTimeout(logger, timeout)(url, options).catch(
    handleFetchErrors(logger),
  );
};

/**
 * @deprecated use createWithBody(APIMETHOD.POST) instead
 */
export const createPost = logger => createWithBody(logger)(APIMETHOD.POST);

export const getBasicAuth = (username, password) =>
  `Basic ${Buffer.from(`${username}:${password}`).toString(ENCODING.BASE64)}`;

export const applyRouteParams = (route, parameters = {}) =>
  Object.keys(parameters).reduce(
    (prevRoute, param) => prevRoute.replace(`{${param}}`, parameters[param]),
    route,
  );

/**
 * @typedef {({ endpoint: string | undefined, parameters: object | undefined, routeParams: object | undefined, headers: object | undefined, timeout: number | undefined }) => Promise<Response>} withoutBodyFn
 */

/**
 * @typedef {({ endpoint, parameters, routeParams, data, headers, bodyType, timeout }) => Promise<Response>} withBodyFn
 */

/**
 * @typedef {Object} API
 * @property {() => string} getBaseUrl
 * @property {() => string} getToken
 * @property {() => Object} getHeaders
 * @property {() => Object} getTypedHeaders
 * @property {(username: String, password: String) => string} getBasicAuth
 * @property {withoutBodyFn} GET
 * @property {withoutBodyFn} DELETE
 * @property {withBodyFn} POST
 * @property {withBodyFn} PUT
 * @property {withBodyFn} PATCH
 * @property {Object} ENCODING
 * @property {Object} ERROR
 * @property {Object} REQUESTBODYTYPE
 * @property {Object} APIMETHOD
 */

/**
 * use this function to create a new api objet.
 * @param {Object} options - An object.
 * @param {object} [options.logger=] logger which is compatible to console, defaults to console
 * @param {string} options.baseUrl backend BaseUrl
 * @param {string} [options.tokenPrefix=] authorizaton token prefix (defaults to "Bearer ") -
 * @param {string} [options.token=] authorization token
 * @param {object} [options.defaultHeaders={}] object with headers to add
 * @param {object} [options.typedHeaders={ Accept: "application/json", "Content-Type": "application/json" }] object with content type headers, defaults to { Accept: "application/json", "Content-Type": "application/json" }
 * @param {number} [options.defaultTimeout=1000] default request timeout
 * @returns {API} a new api object
 */
const create = ({
  logger = console,
  baseUrl: inBaseUrl = '',
  tokenPrefix = 'Bearer ',
  token,
  defaultHeaders: inDefaultHeaders,
  typedHeaders: inTypedHeaders,
  defaultTimeout = 1000,
  credentials: inCredentials,
}) => {
  const withBody = createWithBody(logger);
  const withoutBody = createWithoutBody(logger);

  const baseUrl =
    inBaseUrl.slice(-1) === '/' ? inBaseUrl.slice(0, -1) : inBaseUrl;

  const defaultHeaders = {
    ...(inDefaultHeaders || {}),
  };
  if (token) {
    defaultHeaders.Authorization = `${tokenPrefix}${token}`;
  }

  const typedHeaders = {
    ...defaultHeaders,
    ...(inTypedHeaders || {}),
  };

  const api = {
    getBaseUrl: () => baseUrl,
    getToken: () => token,
    getHeaders: () => defaultHeaders,
    getTypedHeaders: () => typedHeaders,
    getBasicAuth,
    ...apiMethodsWithoutBody.reduce((result, method) => {
      const methodFn = withoutBody(method);
      result[method] = ({
        url,
        endpoint,
        parameters,
        headers,
        routeParams,
        timeout,
        credentials = inCredentials,
      }) =>
        methodFn({
          url,
          timeout: timeout || defaultTimeout,
          baseUrl,
          endpoint: applyRouteParams(endpoint, routeParams),
          parameters,
          headers: { ...typedHeaders, ...headers },
          credentials,
        });
      return result;
    }, {}),
    ...apiMethodsWithBody.reduce((result, method) => {
      const methodFn = withBody(method);
      result[method] = ({
        url,
        endpoint,
        parameters,
        routeParams,
        data,
        headers,
        bodyType,
        timeout,
        credentials = inCredentials,
      }) =>
        methodFn({
          url,
          timeout: timeout || defaultTimeout,
          baseUrl,
          endpoint: applyRouteParams(endpoint, routeParams),
          parameters,
          data,
          headers: {
            ...typedHeaders,
            ...headers,
          },
          bodyType,
          credentials,
        });
      return result;
    }, {}),
    ENCODING,
    ERROR,
    REQUESTBODYTYPE,
    APIMETHOD,
  };
  return api;
};

export default create;
