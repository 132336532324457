export const MEASUREMENT_PROTOCOL_STATES = {
  NOT_STARTED: 'NOT STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
} as const;

export const CUSTOMER_TYPE_OPTIONS = {
  PRIVATE: 116,
  BUSINESS: 117,
} as const;

export const NEIGHBOR_DISTANCE_KEPT_OPTIONS = {
  YES: 'YES',
  NO_CUSTOMER_BEARS_RISK: 'NO_CUSTOMER_BEARS_RISK',
  NO_ABORT_PROCESS: 'NO_ABORT_PROCESS',
} as const;

export const RECKONING_TYPE_OPTIONS = {
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY',
} as const;

export const ROOF_COVERING_OPTIONS = {
  TILES: 'TILES',
  TRAPEZOIDAL_SHEET: 'TRAPEZOIDAL_SHEET',
  PREFA: 'PREFA',
  ICOPAL: 'ICOPAL',
  OTHER: 'OTHER',
  // lookup
  HOHLPFANNE: 'HOHLPFANNE',
  PAPPDOCKEN: 'PAPPDOCKEN',
  SCHIEFER: 'SCHIEFER',
} as const;

export const ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE = {
  ALUMINUM_LARGE: 'ALUMINUM_LARGE',
  STEEL_LARGE: 'STEEL_LARGE',
  STEEL_SMALL: 'STEEL_SMALL',
  ALUMINUM_SMALL: 'ALUMINUM_SMALL',
} as const;

export const FLAT_ROOF_COVERING_OPTIONS = {
  BITUMEN: 'BITUMEN',
  GRAVEL_OR_GREEN_AREA: 'GRAVEL_OR_GREEN_AREA',
  TRAPEZOIDAL_SHEET: 'TRAPEZOIDAL_SHEET',
  PREFA: 'PREFA',
  ICOPAL: 'ICOPAL',
  OTHER: 'OTHER',
  // lookup
  HOHLPFANNE: 'HOHLPFANNE',
  PAPPDOCKEN: 'PAPPDOCKEN',
  SCHIEFER: 'SCHIEFER',
} as const;

export const ROOF_TRUSS_BEAM_MATERIAL_OPTIONS = {
  WOOD: 'WOOD',
  METAL: 'METAL',
  OTHER: 'OTHER',
} as const;

export const ROOF_COVERING_TILES_LOOSE_OPTIONS = {
  YES: 'YES',
  NO: 'NO',
  NOT_APPARENT: 'NOT_APPARENT',
} as const;

export const ROOF_TILE_ATTACHMENT = {
  MORTAR: 'MORTAR',
  CLAMP: 'CLAMP',
  GLUED: 'GLUED',
  CARDBOARD_DOCK: 'CARDBOARD_DOCK',
  SLATE: 'SLATE',
  PANTILE: 'PANTILE',
} as const;

export const ENERGY_FLOOR_OPTIONS = {
  FIRST_FLOOR: 'FIRST_FLOOR',
  SECOND_FLOOR: 'SECOND_FLOOR',
  THIRD_FLOOR: 'THIRD_FLOOR',
  BASEMENT: 'BASEMENT',
} as const;

export const ENERGY_STORAGE_LOCATION_REQUIREMENTS_OPTIONS = {
  YES_STANDING_AND_ON_WALL: 'YES_STANDING_AND_ON_WALL',
  YES_ONLY_ON_WALL: 'YES_ONLY_ON_WALL',
  YES_ONLY_STANDING: 'YES_ONLY_STANDING',
  NO: 'NO',
} as const;

export const ENERGY_STORAGE_AND_METER_DISTANCE_ROOM_OPTIONS = {
  SAME_ROOM: 'SAME_ROOM',
  ONE_OR_TWO_ROOMS_APART: 'ONE_OR_TWO_ROOMS_APART',
  MORE_THAN_TWO_ROOMS_APART: 'MORE_THAN_TWO_ROOMS_APART',
} as const;

export const EQUIPOTENTIAL_BONDING_RAIL_EXIST_OPTIONS = {
  YES: 'YES',
  NO: 'NO',
  NOT_APPARENT: 'NOT_APPARENT',
} as const;

export const SMART_HOME_OPTIONS = {
  NOT_INTERESTED: 'NOT_INTERESTED',
  HEATER_ONLY: 'HEATER_ONLY',
  UNDERFLOOR_HEATING_ONLY: 'UNDERFLOOR_HEATING_ONLY',
  HEATER_AND_UNDERFLOOR_HEATING: 'HEATER_AND_UNDERFLOOR_HEATING',
} as const;

export const LOCATION_OPTIONS = {
  AT_HOUSE: 'AT_HOUSE',
  INSIDE_HOUSE: 'INSIDE_HOUSE',
  AT_PROPERTY_BORDER: 'AT_PROPERTY_BORDER',
} as const;

export const CABLE_ROUTING_TYPE_OPTIONS = {
  UNDERGROUND_CABLE: 'UNDERGROUND_CABLE',
  OVERHEAD_LINE: 'OVERHEAD_LINE',
} as const;

export const FIELD_TYPE = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  ENUM: 'ENUM',
  BOOLEAN: 'BOOLEAN',
  VISUAL_DOCUMENTATION: 'VISUAL_DOCUMENTATION',
  OBJECT: 'OBJECT',
  SELECT: 'SELECT',
  DATE: 'DATE',
  YES_NO_BOOLEAN: 'YES_NO_BOOLEAN',
} as const;

export const MEASUREMENT_PROTOCOL_NUMBER_PROPERTIES = [
  'property_house_electricity_consumption_kwh_per_year',
  'property_heat_electricity_consumption_kwh_per_year',
  'additional_energy_sub_meters_count',
  'energy_meters_count',
  'power_inverter_count',
  'power_inverter_output',
  'existing_photovoltaic_system_peak_output',
  'windows_count',
  'heaters_count',
  'valves_count',
  'heating_circuit_distributors_count',
  'room_controllers_count',
  'property_desired_pv_output_kwp',
  'property_desired_bss_capacity_kwh',
  'property_house_electricity_basic_fee_euro_per_year',
  'property_house_electricity_price_cents_per_kwh',
  'property_heat_electricity_basic_fee_euro_per_year',
  'property_heat_electricity_price_cents_per_kwh',
  'distance_wallbox_energy_meter_cabinet',
  'buildings_count',
  'house_connection_boxes_count',
];

export const MEASUREMENT_PROTOCOL_ROOF_NUMBER_PROPERTIES = [
  'roof_tile_coverage',
  'roof_tile_width',
  'roof_area_tilt',
  'distance_ground_gutter',
  'parapet_width',
  'parapet_height',
  'roof_area_length',
  'roof_area_width',
  'roof_truss_beam_distance',
  'roof_truss_beam_width',
  'roof_truss_beam_height',
  'distance_to_main_building',
];

export const MEASUREMENT_PROTOCOL_ROOF_AREA_NUMBER_PROPERTIES = [
  'length',
  'width',
  'tilt',
];
