import Container from '@mui/material/Container';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import './App.css';
import { ApiContextProvider } from './context/ApiContext';
import { i18n } from './i18n';
import CustomThemeProvider from './theme';

const componentMap = {
  MeasurementProtocolEntry: () =>
    import('./components/MeasurementProtocolEntry'),
  PdfDownloadButtonEntry: () => import('./components/PdfDownloadButtonEntry'),
};

const App = ({ config, component }) => {
  if (!componentMap[component]) {
    return null;
  }
  const Component = React.lazy(componentMap[component]);

  return (
    <ApiContextProvider baseUrl={config.baseUrl}>
      <CustomThemeProvider>
        <I18nextProvider i18n={i18n}>
          <ScopedCssBaseline>
            <Container disableGutters={true} maxWidth={false}>
              <Suspense fallback={<div>Loading...</div>}>
                <Component config={config} />
              </Suspense>
            </Container>
          </ScopedCssBaseline>
        </I18nextProvider>
      </CustomThemeProvider>
    </ApiContextProvider>
  );
};

export default App;
