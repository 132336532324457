import React, { createContext } from 'react';

import { salesBackendApi } from '../config';
import createApi from '../lib/js-api/api';

const { routes, ...restApiConfig } = salesBackendApi;
const initialApiContext = baseUrl => ({
  routes,
  api: createApi({ ...restApiConfig, url: baseUrl || salesBackendApi.url }),
});

export const ApiContext = createContext(initialApiContext);

export const ApiContextProvider = ({ baseUrl, children }) => {
  return (
    <ApiContext.Provider value={initialApiContext(baseUrl)}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
