const getEnvName = () => {
  if (isDevMode) {
    return getDevSettings().host;
  }
  const host = window.location?.host;
  if (typeof host !== 'string') {
    return 'prod';
  }
  if (host.includes('test3')) {
    return 'test3';
  }
  if (host.includes('test1')) {
    return 'test1';
  }
  if (host.includes('staging1')) {
    return 'staging1';
  }
  if (host.includes('localhost')) {
    return 'dev';
  }
  return 'prod';
};

const isDevMode = process.env.NODE_ENV === 'development';

export const getDevSettings = () => {
  if (isDevMode) {
    return {
      host: 'staging1',
      headers: {
        Authorization: `
        Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJhcGk6Ly9kZDYzNGY5Yy03ZjcxLTRiNGQtOTc3Yy1jMTVhYjAyNzQxNGUiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85MjNkZDhiMy0zZjc0LTQ3MGQtYmU3YS01NjY4OWFkY2UyMDMvIiwiaWF0IjoxNzIwNTI1MzgyLCJuYmYiOjE3MjA1MjUzODIsImV4cCI6MTcyMDUyOTY5MiwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhYQUFBQVpEalNLbTgxMHJKWUFra2YzemJrZzRvSXFwQThsNE5PcEhwRmtVUUxPVlRUMWkrZkxwcm93U0tkWGlteE9Hc3giLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNjEzNGU2NjktYmM4MS00ZTgxLWIxOTItMzM3ZjhiODM4MmM3IiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJRQTIiLCJnaXZlbl9uYW1lIjoiQXBwIiwiaXBhZGRyIjoiMjEzLjE4Ny43Ny44MSIsIm5hbWUiOiJBcHAgUUEyIiwib2lkIjoiNDBhZjBkNDMtN2ZhZC00YWFhLWE2OTgtMDYyMmU4YjEwNDk1IiwicmgiOiIwLkFUd0FzOWc5a25RX0RVZS1lbFpvbXR6aUE1eFBZOTF4ZjAxTGwzekJXckFuUVU0OEFPay4iLCJzY3AiOiJTYWxlc0FwcC5BY2Nlc3MiLCJzdWIiOiJ6Q05aWGlIYlc0S2l1cUtJRGFsQzN1aEI5ck9zNUJtbGNvMnlPZnRaM3hRIiwidGlkIjoiOTIzZGQ4YjMtM2Y3NC00NzBkLWJlN2EtNTY2ODlhZGNlMjAzIiwidW5pcXVlX25hbWUiOiJhcHAucWEyQGVrZC1zb2xhci5kZSIsInVwbiI6ImFwcC5xYTJAZWtkLXNvbGFyLmRlIiwidXRpIjoiNnkwcVJldnB0RXVWUTl2OGl3dVJBQSIsInZlciI6IjEuMCJ9.qNUf45lW4wVvnuIwVnABPLuFufcZ_135C_p7SFz0yWpDQ4El3u2iAcf5092Uh6oBuPjjCwcf-EZtbAXOrHDKnzieQQn9UQ9wMBXQIA-YoXSj_43T8yrNtPPfi2rUAvSicaqjod2r8PR9GyY51OgMulkkmFizx_2NDV5iRVqbF7_JwyAcfd9NnbTPiVaTK5xA7IeItDE5PrWovdNYlcaej1JxK5lvUKm7MzScoEJc8Pnx24y3-giO1fCTlzaiYQDy8-_OfJeN4po0tz0l_ey9eQZdO111vPFEBpOKDXn8aUHO2atSDy1z1UdqN7IEbG3vutUlkmxTskdKuCoCoLKAUA 
          `,
      },
      leadId: 537614,
      specialRoofCoverings: ['Pappdocken', 'Schiefer'],
    };
  }
  return {};
};

export const reactStrictMode = false;

const envName = getEnvName();

const salesBackendBaseUrl = {
  test1: 'https://sales-api.test1.leads-middleware.de',
  test3: 'https://sales-api.test3.leads-middleware.de',
  staging1: 'https://sales-api.staging1.leads-middleware.de',
  prod: 'https://sales-api.leads-middleware.de',
  dev: 'http://localhost:8080',
} as const;

export const salesBackendApi = {
  url: salesBackendBaseUrl[envName!],
  endpoint: {
    measurementProtocolFull: 'measurementProtocol/full',
    measurementProtocolPdf: 'measurementProtocol/pdf',
    measurementProtocol: 'measurementprotocol',
    measurementProtocolRoof: 'measurementprotocolroof',
    measurementProtocolHouseConnectionBox:
      'measurementprotocolhouseconnectionbox',
    measurementProtocolRoofArea: 'measurementprotocolroofarea',
    lead: 'lead',
    image: 'image',
    file: 'file',
  },
} as const;

const leadsMiddleWareBaseUrl = {
  test1: 'https://test1.leads-middleware.de/salesAPI/v1',
  test3: 'https://test3.leads-middleware.de/salesAPI/v1',
  staging1: 'https://staging1.leads-middleware.de/salesAPI/v1',
  prod: 'https://leads-middleware.de/salesAPI/v1',
  dev: 'https://staging1.leads-middleware.de/salesAPI/v1',
} as const;

export const leadsMiddlewareApi = {
  url: leadsMiddleWareBaseUrl[envName!],
  endpoint: {
    file: 'file',
  },
} as const;
