import isEmpty from 'lodash/isEmpty';

import {
  DECIMAL_NUMBER_VALIDATION,
  validateFloatNumberOneDecimal0to100Required,
  validateIntNumberMin0,
  validateMeasurementProtocolRequired,
  validateNumberMinToMax,
  validateRequiredIntNumber1to10,
  validateRequiredIntNumber1to50,
  validateRequiredPositiveNumber,
  validateRequiredTextMaxLength100,
} from '.';
import {
  CUSTOMER_TYPE_OPTIONS,
  DRAWING_VALUES,
  FLAT_ROOF_COVERING_OPTIONS,
  NEIGHBOR_DISTANCE_KEPT_OPTIONS,
  ROOF_COVERING_OPTIONS,
  ROOF_COVERING_TILES_LOOSE_OPTIONS,
  ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE,
  ROOF_TILE_ATTACHMENT,
} from '../../config/constants';

// TODO: Also check for the contents inside of the objects to be set correctly here
export const validateMeasurementProtocolVisualRepresentation = (
  _,
  {
    annotatedSatelliteImage,
    annotatedCustomImage,
    obstaclesExist,
    wallboxDesired,
  },
) =>
  isEmpty(annotatedCustomImage) && isEmpty(annotatedSatelliteImage)
    ? 'validation.measurementProtocol.visualRepresentationInStepsNeeded'
    : !isEmpty(annotatedSatelliteImage)
    ? (obstaclesExist &&
        isEmpty(annotatedSatelliteImage?.allEntries?.DANGER)) ||
      isEmpty(annotatedSatelliteImage?.allEntries?.TRUCK) ||
      isEmpty(annotatedSatelliteImage?.allEntries?.SCAFFOLD) ||
      annotatedSatelliteImage?.allEntries.SCAFFOLD.some(scaffold =>
        validateFloatNumberOneDecimal0to100Required(scaffold.length),
      ) ||
      (wallboxDesired && isEmpty(annotatedSatelliteImage?.allEntries?.WALLBOX))
      ? 'validation.measurementProtocol.visualRepresentationInStepsNeeded'
      : null
    : (obstaclesExist && isEmpty(annotatedCustomImage?.allEntries?.DANGER)) ||
      !annotatedCustomImage?.polygons?.some(
        polygon => polygon.mode === DRAWING_VALUES.TRUCK,
      ) ||
      isEmpty(annotatedCustomImage?.allEntries?.SCAFFOLD) ||
      annotatedCustomImage?.allEntries.SCAFFOLD.some(scaffold =>
        validateFloatNumberOneDecimal0to100Required(scaffold.length),
      ) ||
      (wallboxDesired && isEmpty(annotatedCustomImage?.allEntries?.WALLBOX))
    ? 'validation.measurementProtocol.visualRepresentationInStepsNeeded'
    : null;

export const validateMeasurementProtocolDrawDeliveryZone = (
  _,
  { annotatedSatelliteImage, annotatedCustomImage },
) =>
  (annotatedSatelliteImage &&
    !isEmpty(annotatedSatelliteImage.allEntries?.TRUCK)) ||
  (annotatedCustomImage &&
    !isEmpty(annotatedCustomImage?.polygons) &&
    annotatedCustomImage.polygons?.some(
      polygon => polygon.mode === DRAWING_VALUES.TRUCK,
    ))
    ? null
    : 'validation.measurementProtocol.visualRepresentationAndDeliveryZoneNeeded';

export const validateMeasurementProtocolDrawObstacle = (
  _,
  { annotatedSatelliteImage, obstaclesExist, annotatedCustomImage },
) =>
  obstaclesExist &&
  ((!annotatedSatelliteImage && !annotatedCustomImage) ||
    (annotatedSatelliteImage &&
      isEmpty(annotatedSatelliteImage.allEntries?.DANGER)) ||
    (annotatedCustomImage && isEmpty(annotatedCustomImage.allEntries?.DANGER)))
    ? 'validation.measurementProtocol.visualRepresentationNeeded'
    : null;

export const validateMeasurementProtocolDrawScaffold = (
  _,
  { annotatedSatelliteImage, annotatedCustomImage },
) =>
  (annotatedSatelliteImage &&
    !isEmpty(annotatedSatelliteImage?.allEntries?.SCAFFOLD) &&
    !annotatedSatelliteImage?.allEntries?.SCAFFOLD.some(scaffold =>
      validateFloatNumberOneDecimal0to100Required(scaffold.length),
    )) ||
  (annotatedCustomImage &&
    !isEmpty(annotatedCustomImage?.allEntries?.SCAFFOLD) &&
    !annotatedCustomImage?.allEntries?.SCAFFOLD.some(scaffold =>
      validateFloatNumberOneDecimal0to100Required(scaffold.length),
    ))
    ? null
    : 'validation.measurementProtocol.visualRepresentationScaffoldNeeded';

export const validateMeasurementProtocolDrawWallbox = (
  _,
  { annotatedSatelliteImage, wallboxDesired, annotatedCustomImage },
  __,
  { isInitialAnalysisProtocol },
) =>
  (!isInitialAnalysisProtocol &&
    wallboxDesired &&
    !annotatedSatelliteImage &&
    !annotatedCustomImage) ||
  (!isInitialAnalysisProtocol &&
    wallboxDesired &&
    annotatedSatelliteImage &&
    isEmpty(annotatedSatelliteImage?.allEntries?.WALLBOX)) ||
  (!isInitialAnalysisProtocol &&
    wallboxDesired &&
    annotatedCustomImage &&
    isEmpty(annotatedCustomImage?.allEntries?.WALLBOX))
    ? 'validation.measurementProtocol.visualRepresentationNeeded'
    : null;

export const validateMeasurementProtocolPowerInverterManufacturer = (
  powerInverterManufacturer,
  { photovoltaicSystemExists },
) =>
  photovoltaicSystemExists === true
    ? validateRequiredTextMaxLength100(powerInverterManufacturer)
    : null;

export const validateMeasurementProtocolPowerInverterType = (
  powerInverterType,
  { photovoltaicSystemExists },
) =>
  photovoltaicSystemExists === true
    ? validateRequiredTextMaxLength100(powerInverterType)
    : null;

export const validateMeasurementProtocolPowerInverterCount = (
  powerInverterCount,
  { photovoltaicSystemExists },
) =>
  photovoltaicSystemExists === true
    ? validateIntNumberMin0(powerInverterCount)
    : null;

export const validateMeasurementProtocolPowerInverterOutput = (
  powerInverterOutput,
  { photovoltaicSystemExists },
) =>
  photovoltaicSystemExists === true
    ? validateIntNumberMin0(powerInverterOutput)
    : null;

export const validateMeasurementProtocolExistingPhotovoltaicSystemPeakOutput = (
  existingPhotovoltaicSystemPeakOutput,
  { photovoltaicSystemExists },
) =>
  photovoltaicSystemExists === true
    ? validateRequiredPositiveNumber(existingPhotovoltaicSystemPeakOutput)
    : null;

export const validateMeasurementProtocolExistingPhotovoltaicSystemExcessFeedIn =
  (existingPhotovoltaicSystemExcessFeedIn, { photovoltaicSystemExists }) =>
    photovoltaicSystemExists === true
      ? validateMeasurementProtocolRequired(
          existingPhotovoltaicSystemExcessFeedIn,
        )
      : null;

export const validateMeasurementProtocolExistingPhotovoltaicSystemShouldGetIntegrated =
  (
    existingPhotovoltaicSystemShouldGetIntegrated,
    { photovoltaicSystemExists },
  ) =>
    photovoltaicSystemExists === true
      ? validateMeasurementProtocolRequired(
          existingPhotovoltaicSystemShouldGetIntegrated,
        )
      : null;

export const validateMeasurementProtocolOtherPowerPlantDescription = (
  otherPowerPlantDescription,
  { powerGeneratingPlantExists, otherPowerPlantExists },
) =>
  otherPowerPlantExists && powerGeneratingPlantExists
    ? validateRequiredTextMaxLength100(otherPowerPlantDescription)
    : null;

export const validateMeasurementProtocolDistanceWallboxEnergyMeterCabinetSufficient =
  (distanceWallboxEnergyMeterCabinetSufficient, { wallboxDesired }) =>
    wallboxDesired === true
      ? validateMeasurementProtocolRequired(
          distanceWallboxEnergyMeterCabinetSufficient,
        )
      : null;

export const validateMeasurementProtocolWindowsCount = (
  windowsCount,
  { smartHome },
) =>
  smartHome === 'HEATER_ONLY' ||
  smartHome === 'UNDERFLOOR_HEATING_ONLY' ||
  smartHome === 'HEATER_AND_UNDERFLOOR_HEATING'
    ? validateRequiredIntNumber1to50(windowsCount)
    : null;

export const validateMeasurementProtocolHeatersCount = (
  heatersCount,
  { smartHome },
) =>
  smartHome === 'HEATER_ONLY' || smartHome === 'HEATER_AND_UNDERFLOOR_HEATING'
    ? validateRequiredIntNumber1to50(heatersCount)
    : null;

export const validateMeasurementProtocolValvesCount = (
  valvesCount,
  { smartHome },
) =>
  smartHome === 'HEATER_ONLY' || smartHome === 'HEATER_AND_UNDERFLOOR_HEATING'
    ? validateRequiredIntNumber1to50(valvesCount)
    : null;

export const validateMeasurementProtocolHeatingCircuitDistributorsCount = (
  heatingCircuitDistributorsCount,
  { smartHome },
) =>
  smartHome === 'UNDERFLOOR_HEATING_ONLY' ||
  smartHome === 'HEATER_AND_UNDERFLOOR_HEATING'
    ? validateRequiredIntNumber1to10(heatingCircuitDistributorsCount)
    : null;

export const validateMeasurementProtocolRoomControllersCount = (
  roomControllersCount,
  { smartHome },
) =>
  smartHome === 'UNDERFLOOR_HEATING_ONLY' ||
  smartHome === 'HEATER_AND_UNDERFLOOR_HEATING'
    ? validateRequiredIntNumber1to10(roomControllersCount)
    : null;

export const validateMeasurementProtocolCustomerDlanModel = (
  customerDlanModel,
  { customerDlanExists },
) =>
  customerDlanExists === true
    ? validateRequiredTextMaxLength100(customerDlanModel)
    : null;

export const validateMeasurementProtocolRoofTileAttachment = (
  roofTileAttachment,
  values,
  _,
  additionalInformation,
) =>
  values?.roofs?.[additionalInformation?.entityId]?.flatRoof ||
  values?.roofs?.[additionalInformation?.entityId]?.roofCovering !==
    ROOF_COVERING_OPTIONS.TILES ||
  (values.roofs?.[additionalInformation?.entityId]?.roofCoveringTilesLoose !==
    ROOF_COVERING_TILES_LOOSE_OPTIONS.NO &&
    values.roofs?.[additionalInformation?.entityId]?.roofCoveringTilesLoose !==
      ROOF_COVERING_TILES_LOOSE_OPTIONS.NOT_APPARENT) ||
  [
    ROOF_TILE_ATTACHMENT.MORTAR,
    ROOF_TILE_ATTACHMENT.CLAMP,
    ROOF_TILE_ATTACHMENT.GLUED,
  ].includes(roofTileAttachment)
    ? null
    : 'validation.measurementProtocol.abortMeasurementProtocol';

export const validateMeasurementProtocolNeighborDistanceKept =
  neighborDistanceKeptOption => {
    if (
      neighborDistanceKeptOption ===
      NEIGHBOR_DISTANCE_KEPT_OPTIONS.NO_ABORT_PROCESS
    ) {
      return 'validation.measurementProtocol.abortMeasurementProtocol';
    }
    if (!neighborDistanceKeptOption) {
      return 'validation.required';
    }
    return null;
  };

export const validateMeasurementProtocolNeighborDistanceKeptOptional =
  neighborDistanceKeptOption => {
    if (
      neighborDistanceKeptOption ===
      NEIGHBOR_DISTANCE_KEPT_OPTIONS.NO_ABORT_PROCESS
    ) {
      return 'validation.measurementProtocol.abortMeasurementProtocol';
    }
    return null;
  };

export const validateMeasurementProtocolHouseElectricityConsumptionKwhPerYear =
  (houseElectricityConsumptionKwhPerYear, { propertyLoadprofileId }) => {
    if (propertyLoadprofileId === CUSTOMER_TYPE_OPTIONS.BUSINESS) {
      return validateNumberMinToMax(
        0,
        100000,
        DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
        true,
      )(houseElectricityConsumptionKwhPerYear);
    } else {
      return validateNumberMinToMax(
        0,
        30000,
        DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
        true,
      )(houseElectricityConsumptionKwhPerYear);
    }
  };

export const validateMeasurementProtocolHouseElectricityConsumptionKwhPerYearOptional =
  (houseElectricityConsumptionKwhPerYear, { propertyLoadprofileId }) => {
    if (propertyLoadprofileId === CUSTOMER_TYPE_OPTIONS.BUSINESS) {
      return validateNumberMinToMax(
        0,
        100000,
        DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
        false,
      )(houseElectricityConsumptionKwhPerYear);
    } else {
      return validateNumberMinToMax(
        0,
        30000,
        DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
        false,
      )(houseElectricityConsumptionKwhPerYear);
    }
  };

export const validateMeasurementProtocolHeatElectricityConsumptionKwhPerYear = (
  heatElectricityConsumptionKwhPerYear,
  { propertyLoadprofileId },
) => {
  if (propertyLoadprofileId === CUSTOMER_TYPE_OPTIONS.BUSINESS) {
    return validateNumberMinToMax(
      0,
      100000,
      DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
      false,
    )(heatElectricityConsumptionKwhPerYear);
  } else {
    return validateNumberMinToMax(
      0,
      30000,
      DECIMAL_NUMBER_VALIDATION.NO_DECIMALS,
      false,
    )(heatElectricityConsumptionKwhPerYear);
  }
};

export const roofCoveringTrapezoidalMaterialSizeValidation = (
  roofCoveringTrapezoidalMaterialSize,
  values,
  _,
  additionalInformation,
) => {
  if (values.roofs?.[additionalInformation?.entityId]?.flatRoof === false) {
    const validation =
      !values.roofs?.[additionalInformation?.entityId]?.roofCovering ===
        ROOF_COVERING_OPTIONS.TRAPEZOIDAL_SHEET ||
      values.roofs?.[additionalInformation?.entityId]?.roofCovering !==
        ROOF_COVERING_OPTIONS.TRAPEZOIDAL_SHEET ||
      [
        ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE.ALUMINUM_LARGE,
        ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE.STEEL_LARGE,
      ].includes(roofCoveringTrapezoidalMaterialSize)
        ? null
        : 'validation.measurementProtocol.abortMeasurementProtocol';
    return validation;
  } else if (
    values.roofs?.[additionalInformation?.entityId]?.flatRoof === true
  ) {
    const validation =
      !values.roofs?.[additionalInformation?.entityId]?.flatRoofCovering ===
        FLAT_ROOF_COVERING_OPTIONS.TRAPEZOIDAL_SHEET ||
      values.roofs?.[additionalInformation?.entityId]?.flatRoofCovering !==
        FLAT_ROOF_COVERING_OPTIONS.TRAPEZOIDAL_SHEET ||
      [
        ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE.ALUMINUM_LARGE,
        ROOF_COVERING_TRAPEZOIDAL_MATERIAL_SIZE.STEEL_LARGE,
      ].includes(roofCoveringTrapezoidalMaterialSize)
        ? null
        : 'validation.measurementProtocol.abortMeasurementProtocol';
    return validation;
  } else {
    return null;
  }
};
