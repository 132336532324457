import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

import {
  DEFAULT_PHONE_NUMBER_COUNTRY_CODE,
  LEAD_STATE,
  VALIDATION_MESSAGE,
} from '../../config';

export * from './measurementProtocol';

export const DECIMAL_NUMBER_VALIDATION = {
  NO_VALIDATION: '',
  NO_DECIMALS: 'noDecimal',
  ONE_DECIMAL: 'oneDecimal',
  TWO_DECIMAL: 'twoDecimal',
};

const VALIDATION_REGEXES = {
  isValidEmailAddressRegExp:
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  isIntNumberRegExp: /^[0-9]*[0-9][0-9]*$/,
  isNumberRegExp: /^\d*,?\d*$/,
  isNumberRegExpNoDecimal: /^\d+$/,
  isNumberRegExpOneDecimal: /^\d*,?\d{0,1}$/,
  isNumberRegExpTwoDecimal: /^\d*,?\d{0,2}$/,
};

export const validateMessage = value => {
  if (!value) {
    return 'validation.required';
  }
  if (value.trim().length === 0) {
    return 'validation.emptyMessage';
  }
  return null;
};

export const validateRequired = value => {
  if (!value) {
    return 'validation.required';
  }
  return null;
};
export const validateMeasurementProtocolRequired = value => {
  if (value === undefined || value === null) {
    return 'validation.required';
  }
  return null;
};

export const validateLeadIdRequired = value => {
  if (!value?.id) {
    return 'validation.required';
  }
  return null;
};

export const validateZip = value => {
  if (!value) {
    return 'validation.required';
  }
  if (value.length > 5) {
    return 'validation.lengthZip';
  }
  return null;
};

export const validateStartEndDateTimes = ({
  startTime,
  endTime,
  startDate,
  endDate,
}) => {
  if (
    !isSameDay(new Date(startTime), new Date(endTime)) &&
    !isAfter(new Date(endTime), new Date(startTime))
  ) {
    return VALIDATION_MESSAGE.DATE_END_BEFORE_START;
  } else {
    if (
      isAfter(new Date(startTime), new Date(endTime)) ||
      isAfter(new Date(startDate), new Date(endDate))
    ) {
      return VALIDATION_MESSAGE.DATE_END_BEFORE_START;
    }
  }

  return null;
};

export const validatePhoneNumbers = values => {
  let errors = [];
  values.forEach(({ value }, i) => {
    const asYouType = new AsYouType(DEFAULT_PHONE_NUMBER_COUNTRY_CODE);
    const parsedPhoneNumber = value && asYouType.input(value);
    if (
      !value ||
      !isValidPhoneNumber(parsedPhoneNumber, DEFAULT_PHONE_NUMBER_COUNTRY_CODE)
    ) {
      errors.push('validation.invalidPhoneNumber');
    } else {
      errors.push(null);
    }
  });
  return errors;
};

export const validateEmailAddresses = values => {
  let errors = [];
  values.forEach(({ value }, i) => {
    if (
      value &&
      !VALIDATION_REGEXES.isValidEmailAddressRegExp.test(value.toLowerCase())
    ) {
      errors.push('validation.invalidEmailAddress');
    } else {
      errors.push(null);
    }
  });
  return errors;
};

export const validateIntNumber = value => {
  if (value && !VALIDATION_REGEXES.isIntNumberRegExp.test(value)) {
    return 'validation.onlyIntegerNumbers';
  }
  return null;
};

export const validateNumber = value => {
  if (value && !VALIDATION_REGEXES.isNumberRegExp.test(value)) {
    return 'validation.numberRequired';
  }
  return null;
};

// If you use this add the validation key to the translation file
const validateIntNumberMinToMax = (min, max, isRequired) => value => {
  if (isRequired && !value) {
    return `validation.onlyIntegerNumbersFrom${min}to${max}`;
  }

  if (
    (value || value === 0) &&
    (!VALIDATION_REGEXES.isIntNumberRegExp.test(value) ||
      (min !== undefined && value < min) ||
      (max !== undefined && value > max))
  ) {
    return `validation.onlyIntegerNumbersFrom${min}to${max}`;
  }

  return null;
};

// If you use this add the validation key to the translation file
const validateIntNumberMin = (min, isRequired) => value => {
  if (isRequired && !value) {
    return `validation.onlyIntegerNumberFrom${min}`;
  }

  if (
    value &&
    (!VALIDATION_REGEXES.isIntNumberRegExp.test(value) ||
      (min !== undefined && value < min))
  ) {
    return `validation.onlyIntegerNumberFrom${min}`;
  }

  return null;
};

export const validateNumberMinToMax =
  (min, max, variant = DECIMAL_NUMBER_VALIDATION.NO_VALIDATION, isRequired) =>
  value => {
    if (isRequired && !value) {
      return `validation.onlyNumbersFrom${min}to${max}${variant}`;
    }
    let regex = VALIDATION_REGEXES.isIntNumberRegExp;

    if (variant === DECIMAL_NUMBER_VALIDATION.NO_DECIMALS) {
      regex = VALIDATION_REGEXES.isNumberRegExpNoDecimal;
    } else if (variant === DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL) {
      regex = VALIDATION_REGEXES.isNumberRegExpOneDecimal;
    } else if (variant === DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL) {
      regex = VALIDATION_REGEXES.isNumberRegExpTwoDecimal;
    }

    const valueAsFloatNumber =
      (value || value === 0) && parseFloat(value.toString().replace(',', '.'));

    if (
      (value || value === 0) &&
      (!regex.test(value) ||
        (min !== undefined && valueAsFloatNumber < min) ||
        (max !== undefined && valueAsFloatNumber > max))
    ) {
      return `validation.onlyNumbersFrom${min}to${max}${variant}`;
    }
    return null;
  };

export const validateNumberMin =
  (
    min,
    equal = true,
    variant = DECIMAL_NUMBER_VALIDATION.NO_VALIDATION,
    isRequired,
  ) =>
  value => {
    if (isRequired && !value) {
      return `validation.onlyNumbersFrom${min}${variant}`;
    }

    let regex = VALIDATION_REGEXES.isIntNumberRegExp;

    if (variant === DECIMAL_NUMBER_VALIDATION.NO_DECIMALS) {
      regex = VALIDATION_REGEXES.isNumberRegExpNoDecimal;
    } else if (variant === DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL) {
      regex = VALIDATION_REGEXES.isNumberRegExpOneDecimal;
    } else if (variant === DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL) {
      regex = VALIDATION_REGEXES.isNumberRegExpTwoDecimal;
    }

    const valueAsFloatNumber =
      value && parseFloat(value.toString().replace(',', '.'));

    if (
      value &&
      (!regex.test(value) ||
        (min !== undefined && equal
          ? valueAsFloatNumber < min
          : valueAsFloatNumber <= min))
    ) {
      return `validation.onlyNumbersFrom${min}${variant}`;
    }

    return null;
  };

export const validateRequiredIntNumber1to5 = validateIntNumberMinToMax(
  1,
  5,
  true,
);

export const validateRequiredIntNumber1to10 = validateIntNumberMinToMax(
  1,
  10,
  true,
);

export const validateRequiredIntNumber1to50 = validateIntNumberMinToMax(
  1,
  50,
  true,
);

export const validateIntNumberMin0 = validateIntNumberMin(1, true);
export const validateIntNumberMin0Optional = validateIntNumberMin(1, false);
export const validateIntNumber0to5 = validateIntNumberMinToMax(0, 5);
export const validateIntNumber1to5 = validateIntNumberMinToMax(1, 5);
export const validateIntNumber0to10 = validateIntNumberMinToMax(0, 10);
export const validateIntNumber0to90 = validateIntNumberMinToMax(0, 90);
export const validateIntNumber0to90Required = validateIntNumberMinToMax(
  0,
  90,
  true,
);
export const validateIntNumber1to10 = validateIntNumberMinToMax(1, 10);
export const validateIntNumber10to30required = validateIntNumberMinToMax(
  10,
  30,
  true,
);
export const validateIntNumber10to30 = validateIntNumberMinToMax(10, 30, false);
export const validateIntNumber1to50 = validateIntNumberMinToMax(1, 50);
export const validateIntNumber0to100 = validateIntNumberMinToMax(0, 100);
export const validateIntNumber10to100 = validateIntNumberMinToMax(10, 100);
export const validateIntNumber0to1000 = validateIntNumberMinToMax(0, 1000);
export const validateIntNumber0to2000 = validateIntNumberMinToMax(0, 2000);
export const validateIntNumber0to2000Required = validateIntNumberMinToMax(
  0,
  2000,
  true,
);
export const validateIntNumber0to100k = validateIntNumberMinToMax(0, 100000);
export const validateIntNumber0to300k = validateIntNumberMinToMax(0, 300000);
export const validateFloatNumberOneDecimal0to100 = validateNumberMinToMax(
  0,
  100,
  DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL,
);
export const validateFloatNumberOneDecimal0to150 = validateNumberMinToMax(
  0,
  150,
  DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL,
);
export const validateIntNumber1to100 = validateIntNumberMinToMax(1, 100);
export const validateFloatNumberOneDecimal1to100 = validateNumberMinToMax(
  1,
  100,
  DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL,
);
export const validateFloatNumberOneDecimal1to100Required =
  validateNumberMinToMax(1, 100, DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL, true);
export const validateFloatNumberOneDecimal0to100Required =
  validateNumberMinToMax(0, 100, DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL, true);

export const validateFloatNumberOneDecimal0to150Required =
  validateNumberMinToMax(0, 150, DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL, true);

export const validateFloatNumberOneDecimal10to100 = validateNumberMinToMax(
  10,
  100,
  DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL,
);
export const validateFloatNumberOneDecimal10to100Required =
  validateNumberMinToMax(10, 100, DECIMAL_NUMBER_VALIDATION.ONE_DECIMAL, true);
export const validateFloatNumberTwoDecimal0to100 = validateNumberMinToMax(
  0,
  100,
  DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL,
);
export const validateFloatNumberTwoDecimal0to100Required =
  validateNumberMinToMax(0, 100, DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL, true);
export const validateFloatNumberTwoDecimal1to100 = validateNumberMinToMax(
  1,
  100,
  DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL,
);
export const validateFloatNumberTwoDecimal0to1000 = validateNumberMinToMax(
  0,
  1000,
  DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL,
);
export const validateFloatNumberTwoDecimal0to1000Required =
  validateNumberMinToMax(0, 1000, DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL, true);
export const validateFloatNumberTwoDecimal0to100k = validateNumberMinToMax(
  0,
  100000,
  DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL,
);
export const validateFloatNumberTwoDecimal0to100kRequired =
  validateNumberMinToMax(
    0,
    100000,
    DECIMAL_NUMBER_VALIDATION.TWO_DECIMAL,
    true,
  );

export const validateTextMaxLength50 = value => {
  if (value && value.length > 50) {
    return 'validation.textLength';
  }
  return null;
};

export const validateTextMaxLength100 = value => {
  if (value && value.length > 100) {
    return 'validation.validate0to100';
  }
  return null;
};

export const validateTextMaxLength200 = value => {
  if (value && value.length > 200) {
    return 'validation.validate0to200';
  }
  return null;
};

export const validateRequiredPositiveNumber = value => {
  if (
    !value ||
    (value && value <= 0) ||
    !VALIDATION_REGEXES.isNumberRegExp.test(value)
  ) {
    return 'validation.positiveNumberRequired';
  }
  return null;
};

export const validatePositiveNumber = value => {
  if ((value && value <= 0) || !VALIDATION_REGEXES.isNumberRegExp.test(value)) {
    return 'validation.positiveNumberRequired';
  }
  return null;
};

export const validateRequiredTextMaxLength50 = value => {
  if (!value || (value && value.length > 50)) {
    return 'validation.textLengthRequired50';
  }
  return null;
};

export const validateRequiredPositiveIntegerNumberWithAdditionalDependencies = (
  value,
  ...additionalValues
) => {
  const regex = VALIDATION_REGEXES.isNumberRegExpNoDecimal;

  const maxLengthConditionHasError =
    value && validateRequiredTextMaxLength100(value);
  const mainConditionHasError =
    !value || (value && value <= 0) || (value && !regex.test(value));
  const additionalValuesAllowValidation = additionalValues.some(
    additionalValue => !additionalValue,
  );

  if (
    !additionalValuesAllowValidation &&
    (mainConditionHasError || maxLengthConditionHasError)
  ) {
    return 'validation.onlyIntegerNumberFrom1';
  }
  return null;
};

export const validateRequiredTextWithAdditionalDependencies = (
  value,
  ...additionalValues
) => {
  const maxLengthConditionHasError =
    value && validateRequiredTextMaxLength100(value);
  const additionalValuesAllowValidation = additionalValues.some(
    additionalValue => !additionalValue,
  );

  if (!additionalValuesAllowValidation && maxLengthConditionHasError) {
    return 'validation.textLengthRequired100';
  }
  return null;
};

export const validatePositiveIntegerNumber = value => {
  const regex = VALIDATION_REGEXES.isNumberRegExpNoDecimal;

  const maxLengthConditionHasError =
    value && validateRequiredTextMaxLength100(value);
  const mainConditionHasError =
    !value || (value && value <= 0) || (value && !regex.test(value));

  if (mainConditionHasError || maxLengthConditionHasError) {
    return 'validation.onlyIntegerNumberFrom1';
  }
  return null;
};

export const validateRequiredTextMaxLength100 = value => {
  if (!value || (value && value.length > 100)) {
    return 'validation.textLengthRequired100';
  }
  return null;
};

export const validateRequiredTextMaxLength200 = value => {
  if (!value || (value && value.length > 200)) {
    return 'validation.textLengthRequired200';
  }
  return null;
};

export const validateRequiredTextMaxLength500 = value => {
  if (!value || (value && value.length > 500)) {
    return 'validation.textLengthRequired500';
  }
  return null;
};

export const validateNonRequiredTextMaxLength500 = value => {
  if (value && value.length > 500) {
    return 'validation.textLengthNonRequired500';
  }
  return null;
};

export const validateTextMaxLength1500 = value => {
  if (value && value.length > 1500) {
    return 'validation.textLength1500';
  }
  return null;
};

export const validateTextMaxLength500 = value => {
  if (!value) {
    return 'validation.required';
  }
  if (value.length > 500) {
    return 'validation.textLength1500';
  }
  return null;
};

const validatePhaseChangeHelper = (newLeadphase, formValues, oldLeadPhase) => {
  let allow = true;

  // Mandatory fields for change from 'Aufmass Termin' to 'Angebot in Bearbeitung'
  const areMandatoryFieldsFilled =
    formValues?.roof_pitch_degree !== (null ?? undefined ?? '') &&
    formValues?.house_electricity_consumption_kwh_per_year !==
      (null ?? undefined ?? '') &&
    formValues?.house_electricity_basic_fee_euro_per_year !==
      (null ?? undefined ?? '') &&
    formValues?.house_electricity_price_cents_per_kwh !==
      (null ?? undefined ?? '') &&
    formValues?.distance_ground_gutter_m !== (null ?? undefined ?? '') &&
    formValues?.roof_tile_length_mm !== (null ?? undefined ?? '') &&
    formValues?.roof_tile_width_mm !== (null ?? undefined ?? '') &&
    formValues?.solar_edge_width_m !== (null ?? undefined ?? '');

  // Validate change from 'Aufmass Termin' to 'Angebot in Bearbeitung'
  if (oldLeadPhase === 3 && newLeadphase === 8) {
    allow = areMandatoryFieldsFilled ? true : false;
  }
  return allow;
};

export const validateLeadEditPhaseChange = (
  newLeadphase,
  formValues,
  oldLeadPhase,
) => {
  const allowPhaseChange = validatePhaseChangeHelper(
    newLeadphase,
    formValues,
    oldLeadPhase,
  );

  const leadState = formValues?.state;
  if (leadState === LEAD_STATE.OPEN && !allowPhaseChange) {
    return 'validation.leadPhaseFillInMandatoryFields';
  }
  if (leadState === LEAD_STATE.OPEN || newLeadphase === oldLeadPhase) {
    return null;
  }
  return 'validation.leadPhaseChangeOnlyAllowedForOpenLeads';
};

export const validateValueMustBeTrue = value => {
  if (value !== true) {
    return 'validation.validateValueMustBeTrue';
  }
  return null;
};

export const validateTrueOrFalse = value => {
  if (value !== true && value !== false) {
    return 'validation.required';
  }
  return null;
};

export const validateAudioRecordDescription = (
  value,
  audio,
  isCalendarEntryNote,
) => {
  if (!isCalendarEntryNote) {
    return null;
  }
  return value && audio ? null : 'validation.required';
};
